import { Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {isAuthenticatedGuard} from "./guards/is-authenticated.guard";

export const routes: Routes = [
  {
    path:'',
    pathMatch:'full',
    redirectTo:'dashboard'
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'dashboard',
    canActivate:[isAuthenticatedGuard()],
    loadChildren: ()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)
  }
];
