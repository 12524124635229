import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS,  HttpClientModule, HttpHandler} from "@angular/common/http";
import {LoginInterceptor} from "./interceptors/login-interceptor.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    {provide:HTTP_INTERCEPTORS, useClass:LoginInterceptor, multi:true},
  ]
};
