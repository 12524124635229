import {ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {environment} from "../../environments/environment";
import {login} from "../api/endpoints";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthenticateService} from "../services/auth/authenticate.service";
import {User} from "../repositories/User";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSuffix,
    MatIconButton,
    MatButton,
    MatIconModule,
    HttpClientModule,
    MatError,
    MatHint,
  ],

  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit{

  public user:string = ''
  public password:string = ''
  public showPassword:boolean = false
  public errors = {password:false, user:false}
  public wrongDetails= false
  private api = inject(HttpClient)
  private auth = inject(AuthenticateService)
  private userSignal = inject(User)
  private router = inject(Router)


  public attemptLogin() {
    if(!this.user || !this.password) {
      this.errors.password = !this.password
      this.errors.user = !this.user
      return
    }
    this.api.post(login, {user:this.user, password:this.password}).subscribe(
      (response:any)=>{
        this.auth.authenticate(response.data.user.auth_token)
        this.userSignal.updateUser(response.data.user)
        this.router.navigateByUrl('/dashboard')
      },
      ()=>{
        this.wrongDetails = true
        this.password = ''
        this.user = ''
      }
    )
  }

  ngOnInit(): void {
    console.log(environment.title)
  }
}
