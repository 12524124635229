import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthenticateService} from "../services/auth/authenticate.service";

export function isAuthenticatedGuard(): CanActivateFn  {
  return ()=> {
    const router:Router = inject(Router)
    const auth = inject(AuthenticateService)
    if(!auth.isAuth()) {
      router.navigateByUrl('login')
      return false
    }else{
      return true
    }
  }
}
