<div class=" flex items-center justify-center w-full h-[100vh]">

  <div class="login-box border rounded-xl max-w-[500px] w-full flex flex-col p-5">
    <p class="text-[20px] text-center pt-5 pb-4">Login</p>
    @if(wrongDetails){
      <div class="w-full">
        <p class="text-red-400 text-center "> **Wrong details**</p>
      </div>
    }

    <mat-form-field class="my-2">
      <mat-label>User</mat-label>
      <input matInput type="text" [(ngModel)]="user" >
      <mat-hint>This might be your company email</mat-hint>

      @if (user) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="user=''">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if(errors.user){

      }
      <mat-error>Insert a user!</mat-error>

    </mat-form-field>
    <div class="h-[30px]" ></div>

      <mat-form-field >
        <mat-label>Password</mat-label>
        <input matInput [type]="showPassword?'text':'password'" [(ngModel)]="password" (keyup.enter)="attemptLogin()">
        @if (password) {
          <mat-icon matSuffix (click)="showPassword= !showPassword">
            @if (!showPassword){
              visibility
            } @else {
              visibility_off
            }</mat-icon>
        }
      </mat-form-field>

      <div class="actions my-4 w-full flex justify-center">
        <button class="" mat-raised-button color="primary" (click)="attemptLogin()">Login</button>
      </div>
  </div>
</div>

